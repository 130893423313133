<template>
	<div>
			<div class="pd30">
				<div class="wxb-table-gray">
					<a-table rowKey="order_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
						:data-source="datas" :loading="loading">
						
						<div class="flex alcenter center" slot="project" slot-scope="project,record">
							{{project.project_name}}<span v-if="project.sku_id > 0">({{project.sku_name}})</span>
						</div>
						
						<div class="flex alcenter center" slot="contact" slot-scope="contact,record">
							{{contact.name}},{{contact.mobile}}
						</div>
						<div class="flex alcenter center" slot="car" slot-scope="car,record">
							{{car.car_name}},{{car.car_num}}
						</div>
						<div class="flex alcenter center" slot="price" slot-scope="price,record">
							¥{{price}}
						</div>
						<div class="flex alcenter center" slot="time" slot-scope="time,record">
							{{time.day}},{{time.yuyue_time}}
						</div>
						<div class="text-center" slot="mendian" slot-scope="mendian,record">
							{{mendian ? mendian.name : '-'}}
						</div>
					</a-table>
				</div>
			</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default{
		mixins: [listMixin],
		props:{
			member_id:{
				type:Number,
				default:0,
			}
		},
		data(){
			return{
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				
				columns: [
					
					{title: '编号',dataIndex: 'order_id',align: 'center'},
					{title: '项目类型',key: 'project',align: 'center',width:300,scopedSlots: {customRender: 'project'}},
					{title: '联系人',key: 'contact',align: 'center',scopedSlots: {customRender: 'contact'}},
					{title: '车牌',key: 'car',align: 'center',scopedSlots: {customRender: 'car'}},
					{title: '门市价',dataIndex: 'price',align: 'center',scopedSlots: {customRender: 'price'}},
					{title: '预约时间',key: 'time',align: 'center',scopedSlots: {customRender: 'time'}},
					{title: '所属门店',dataIndex: 'mendian',align: 'center',scopedSlots: {customRender: 'mendian'}},
					{title: '创建时间',dataIndex: 'add_time_format',align: 'center'},
				
				],
				datas: [],
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getYuyueOrderList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					memberId:this.member_id
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.datas;
					
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			
	
			
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	
</style>
