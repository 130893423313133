<template>
	<div class="pd40">
		<a-spin :spinning="loading">
			<a-button @click="addAct" type="primary">+新增车辆</a-button>
			<div class="flex wrap mt30" v-if="datas.length>0">
				
				<div v-for="(item,index) in datas" :key="index" class="detail-car-item">
					<div class="ft16 ftw600 cl-main">{{item.name}}</div>
					<div class="mt10 ft14 cl-main">车牌：{{item.car_num}}</div>
					<div class="mt10 ft14 cl-notice">上牌时间：{{item.licensing_date}}</div>
					<div class="flex end">
						<a-button @click="editAct(index)" type="link">编辑</a-button>
						<a-button @click="delAct(item.car_id)" type="link">删除</a-button>
					</div>
				</div>
				
			</div>
			
			<div class="flex center mt20" v-else>
				<a-empty/>
			</div>
					
			<div class="flex center mt20">
				<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
			</div>
		</a-spin>
				
		 <a-modal
		      title="车辆操作"
		      :visible="visible"
		      :confirm-loading="confirmLoading"
		      @ok="handleOk"
		      @cancel="handleCancel"
		    >
		      <div class="flex  mt10">
		      	<a-input class="woyaou-input" style="width: 230px;" prefix="车型号" placeholder="请输入车型号" v-model="detail.name" :maxLength="64">
		      	</a-input>
		      	<a-input class="woyaou-input ml20" prefix="车牌" style="width: 230px;" placeholder="请输入车牌" v-model="detail.car_num" :maxLength="64">
		      	</a-input>
		      </div>
			  <div class="mt20">上牌时间</div>
			  <div class="mt10">
			    <a-date-picker  valueFormat="YYYY-MM-DD" v-model="detail.licensing_date"/>
			  </div>
			  
			  
		</a-modal>		
				
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				pagination: {
					current: 1,
					pageSize: 6, //每页中显示10条数据
					total: 0,
				},
				visible:false,
				confirmLoading:false,
				datas:[],
				detail:{
					carId:0,
					member_id:0,
					name:'',
					car_num:'',
					licensing_date:''
				},
			}
		},
		methods:{
			delAct(id){
				this.$http.api('admin/deleteCar',{carId:id}).then(res=>{
					this.$message.success('操作成功');
					this.getLists();
				}).catch(res=>{
					
				});
			},
			handleOk(){
				this.confirmLoading = true;
				this.$http.api('admin/saveCar',this.detail).then(res=>{
					this.$message.success('操作成功');
					this.confirmLoading = false;
					this.visible = false;
					this.getLists();
				}).catch(res=>{
					this.confirmLoading = false;
				})
			},
			handleCancel(){
				this.visible = false;
			},
			editAct(e){
				this.detail = {
					carId:this.datas[e].car_id,
					member_id:this.datas[e].member_id,
					name:this.datas[e].name,
					car_num:this.datas[e].car_num,
					licensing_date:this.datas[e].licensing_date
				}
				this.visible = true;
			},
			addAct(){
				this.detail = {
					carid:0,
					member_id:this.member_id,
					name:'',
					car_num:'',
					licensing_date:''
				};
				this.visible = true;
			},
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getCarList',{
					memberId:this.member_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.datas;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
		}
	}
</script>

<style>
	.detail-car-item{
		width: 320px;
		background: #F7F8FC;
		border-radius: 4px;
		margin-right: 30px;
		margin-bottom:30px;
		padding: 20px;
	}
</style>
