<template>
	<div>
		
		<a-spin :spinning="loading">
			<!-- <div class="">
				<a-button type="primary" icon="plus" @click="applyFlashsaleAct">办理抢购</a-button>
			</div> -->
			<div v-if="datas.length>0">
				<div class="mt16">
					<div class="flex wrap">
						<div class="flash-sale-item" v-for="(item,index) in datas">
							<div class="flex space alcenter" style="width: 100%;">
								<div class="flex alcenter">
									<img :src="item.cover_img"/>
									<div class="ml10">
										<div class="ft14 ftw600 cl-black text-over4">{{item.abbreviation}}</div>
										<div class="flex alcenter mt10">
											<div class="flex alcenter">
												<div class="ft12 cl-black">¥{{item.price}}</div>
												<div class="ft12 cl-notice text-line">¥{{item.market_price}}</div>
											</div>
											<div class="flex alcenter ml20">
												<div class="ft12 ftw400 cl-info">有效期至：</div>
												<div class="ft12 cl-main ">{{item.valid_end_time}}</div>
											</div>
										</div>
										<div class="mt15 flex alcenter">
											<div class="flex alcenter">
												<div class="ft12 ftw400 cl-info text-over4">总次数：</div>
												<div class="ft14 ftw600 cl-black text-over4">{{item.total_num}}次</div>
											</div>
											
											<div class="flex alcenter ml20">
												<div class="ft12 ftw400 cl-info text-over4">剩余次数：</div>
												<div class="ft14 ftw600 cl-theme text-over4">{{item.remain_num}}次</div>
											</div>
										</div>
									</div>
								</div>
								
								<div class="flex alcenter">
									<div class="button-write-off text-over4" v-if="item.remain_num>0 && item.status_another==1" @click="writeOffAct(index)">核销</div>
									<div class="button-write-off-over text-over4" v-if="item.remain_num<=0 && item.status_another==1" >核销完</div>
									<div class="button-write-off-over text-over4" v-if="item.status_another==8" >已完成</div>
									<div class="button-write-off-over  text-over4" v-if="item.status_another==-1">已过期</div>
								</div>
							</div>
						</div>
						</div>
				</div>
			</div>
			
			
			<div class="mt20 flex center" v-else>
				<a-empty/>
			</div>
						
			<div class="flex center mt20">
				<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
			</div>	
			<div v-if="writeOffLoading">
				<write-off :datas="item" :visible="writeOffLoading" @cancel="cancelWriteOff" @ok="okWriteOff"></write-off>
			</div>
			<div v-if="applyFlashsaleVisible">
				<apply-flashsale :visible="applyFlashsaleVisible" @cancel="cancelApplyFlashsale" @ok="okApplyFlashsale"></apply-flashsale>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import writeOff from '../../../../project/components/OrderSnapupDetail/modal/writeOff.vue';
	import {listMixin} from '../../../../../common/mixin/list.js';
	import applyFlashsale from './flashsalelist/modal/applyFlashsale.vue';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		components:{
			writeOff,
			applyFlashsale,
		},
		data(){
			return{
				loading:false,
				applyFlashsaleVisible:false,
				writeOffLoading:false,
				item:{},
				pagination:{
					total:0,
					current:1,
					pageSize:2,
				},
				datas: []
			}
		},
		methods:{
			
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getMemberSnapUpList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					member_id: this.member_id,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					this.loading = false;
				})
			},
			
			//办理次卡
			applyFlashsaleAct(){
				this.applyFlashsaleVisible=true;
			},
			cancelApplyFlashsale(){
				this.applyFlashsaleVisible=false;
			},
			okApplyFlashsale(){
				this.applyFlashsaleVisible=false;
				this.getLists();
			},
			
			writeOffAct(index){
				this.item=this.datas[index];
				this.writeOffLoading=true;
			},
			cancelWriteOff(){
				this.writeOffLoading=false;
			},
			okWriteOff(){
				this.writeOffLoading=false;
				this.getLists();
			},
		}
	}
</script>

<style>
	
	.card-list-item{
		padding-bottom: 24px;
		border-bottom: 1px solid #EBEDF5;
		margin-bottom: 24px;
	}
	
	@media screen and (max-width: 1500px) {
		.flash-sale-item{
			width: 99%;
			height: 116px;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom: 1%;
			padding: 20px;
			line-height: 1;
		}
	}
	
	
	@media screen and (min-width: 1501px)  {
		.flash-sale-item{
			width: 49%;
			height: 116px;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom: 1%;
			padding: 20px;
			line-height: 1;
		}
	}
	
	
	.flash-sale-item img{
		width: 100px;
		height: 80px;
	}
	
	
	
</style>
