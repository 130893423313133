<template>
	<div>
		<a-modal title="送券" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定赠送
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<div class="flex center">
						<a-input-search placeholder="搜索优惠券" style="width: 420px" @search="onSearch" />
					</div>
					
					<div class="mt16">
						<div class="flex center wrap" v-if="datas.length>0">
							<template v-for="(item,index) in datas">
								<div class="detail-modal-send-couopon-item active" v-if="select_ids.indexOf(item.coupon_id)!=-1" >
									<div class="flex space ">
										<div>
											<div class="text-over4 ft14 ftw600 cl-black">{{item.name}}</div>
											<div class="mt15 ft12 ftw400 cl-notice text-over4">满{{item.money_limit}}元可用，不可与其他优惠活动同时使用</div>
											<div class="mt10 ft12 ftw400 cl-info text-over4">有效期：{{item.valid_bg_time}}-{{item.valid_end_time}}</div>
										</div>
										
										<div>
											<div class=" flex end">
												<div class="flex alcenter">
													<div class="ft12 ftwblod cl-price">¥</div>
													<div class="ft24 ftwblod cl-price">{{item.coupon_money}}</div>
												</div>
											</div>
											
											<div class="mt20 flex end">
												<div class="detail-modal-send-couopon-item-selected" @click="removeSelectId(index)">
													<a-icon type="check" class="cl-w"/>
												</div>
											</div>
										</div>
									</div>
								</div>
								
								<div class="detail-modal-send-couopon-item " v-else>
									<div class="flex space ">
										<div>
											<div class="text-over4 ft14 ftw600 cl-black">{{item.name}}</div>
											<div class="mt15 ft12 ftw400 cl-notice text-over4">满{{item.money_limit}}元可用，不可与其他优惠活动同时使用</div>
											<div class="mt10 ft12 ftw400 cl-info text-over4">有效期：{{item.valid_bg_time}}-{{item.valid_end_time}}</div>
										</div>
										
										<div>
											<div class=" flex end">
												<div class="flex alcenter">
													<div class="ft12 ftwblod cl-price">¥</div>
													<div class="ft24 ftwblod cl-price">{{item.coupon_money}}</div>
												</div>
											</div>
											
											<div class="mt20 flex end">
												<div class="detail-modal-send-couopon-item-select" @click="addSelectId(index)">
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
						</div>
						
						<div v-else class="flex center mt20">
							<a-empty/>
						</div>
					</div>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	export default {
		mixins:[listMixin],
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			member_id:{
				type:Number,
				default:0
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				pagination: {
					current: 1,
					pageSize: 3, //每页中显示10条数据
					total: 0,
				},
				search:{
					keyword:'',
				},
				select_ids:[],
				datas:[],
			}
		},
		
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/chooseCoupon',{
					keyword:this.search.keyword,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					type:'setting_balance',
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			onSearch(){
				this.getLists();
			},
			
			/**
			 * 选中
			 * @param {Object} index
			 */
			addSelectId(index) {
				if (this.select_ids.indexOf(this.datas[index].coupon_id) != -1) {
					this.$message.error("您已经选择过这个了");
				}
				this.select_ids.push(this.datas[index].coupon_id);
				console.log(this.select_ids);
			},
			
			/**
			 * 取消选择
			 * @param {Object} index
			 */
			removeSelectId(index) {
				let startIndex = this.select_ids.indexOf(this.datas[index].coupon_id);
				if (startIndex == -1) {
					this.$message.error("您已经取消过这个了");
				}
				this.select_ids.splice(startIndex, 1);
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/sendCoupon',{
					member_id:this.member_id,
					coupon_ids:this.select_ids
				}).then(res=>{
					this.$message.success('送券成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.detail-modal-send-couopon-item{
		width: 420px;
		height: 118px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #EBEDF5;
		margin-bottom: 16px;
		padding: 20px;
	}
	
	.detail-modal-send-couopon-item.active{
		background: #F7F8FC;
		border: none;
	}
	
	.detail-modal-send-couopon-item-tag{
		padding: 4px 8px 4px 8px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 12px;
		
		font-size: 12px;
		color: #4772FF;
	}
	.detail-modal-send-couopon-item-select{
		width: 24px;
		height: 24px;
		border-radius: 4px;
		border: 2px solid #A6AEC2;
		cursor: pointer;
	}
	
	.detail-modal-send-couopon-item-selected{
		width: 24px;
		height: 24px;
		background: #4772FF;
		line-height: 24px;
		text-align: center;
	}
</style>
