<template>
	<div>
		<a-modal title="积分充值" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定充值
				</a-button>
			</template>
			<div>
				<div class="detail-modal-integral-recharge flex center">
					<div style="position: relative;">
						<a-input-number   v-model="form.integral" :precision="0" :min="0" >
							
						</a-input-number>
						<div class="input-number-addon flex alcenter">
							<img src="../../../../../assets/image/woyaoyou_icon_integral@2x.png"/>
						</div>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			member_id:{
				type:Number,
				default:0,
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					integral:0,
				}
			}
		},
		methods: {
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/integralRecharge',{
					member_id:this.member_id,
					integral:this.form.integral,
				}).then(res=>{
					this.$message.success('充值成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
		}
	}
</script>

<style>
	.detail-modal-integral-recharge{
		
	}
	
	.detail-modal-integral-recharge .ant-input-number {
		font-size: 20px;
		line-height: 64px;
		font-weight: bold;
		color: #A6AEC2;
		width: 360px;
		height: 64px;
		border-radius: 4px;
		
	}
	
	
	.detail-modal-integral-recharge .ant-input-number-input {
		width: 360px;
		height: 64px;
		padding: 0 59px;
		border-radius: 4px;
	}
	
	.detail-modal-integral-recharge .ant-input-number-handler-wrap {
		display: none;
	}
	
	.detail-modal-integral-recharge .input-number-addon{
		height: 64px;
		padding-left: 20px;
		position: absolute;
		top: 0px;
		left: 0;
		text-align: right;
		line-height: 64px;
		
		font-size: 40px;
		font-weight: bold;
		color: #232529;
	}
	
	.detail-modal-integral-recharge .input-number-addon img{
		width: 24px;
	}
</style>
