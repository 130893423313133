<template>
	<div>
		<a-modal title="余额充值" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定充值
				</a-button>
			</template>
			<div>
				<div class=" flex center">
					<div class="detail-modal-balance-recharge">
						<div class="pt15 ft14 ftw600 cl-main">实际充值金额</div>
						<div class="detail-modal-balance-recharge-content">
							<div style="position: relative;">
								<a-input-number  v-model="form.balance" :precision="2" :min="0" >
									
								</a-input-number>
								<div class="input-number-addon">¥</div>
							</div>
						</div>
						
						<div class="detail-modal-balance-recharge-footer flex space alcenter">
							<div class="ft14 ftw600 cl-main">赠送金额</div>
							<div>
								<a-input-number  v-model="form.give_balance"  :formatter="value => `¥${value}`" :precision="2" :min="0" />
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			member_id:{
				type:Number,
				default:0
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					balance:0,
					give_balance:0,
				}
			}
		},
		methods: {
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/balanceRecharge',{
					member_id:this.member_id,
					balance:this.form.balance,
					give_balance:this.form.give_balance,
				}).then(res=>{
					this.$message.success('充值成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.detail-modal-balance-recharge{
		width: 360px;
		height: 186px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 0px 20px 0px 20px;
	}
	
	.detail-modal-balance-recharge-content .ant-input-number {
		font-size: 40px;
		line-height: 80px;
		font-weight: bold;
		border: none;
		color: #A6AEC2;
		width: 320px;
		height: 80px;
		border-radius: 4px;
		
	}
	
	
	.detail-modal-balance-recharge-content .ant-input-number-input {
		width: 320px;
		height: 80px;
		padding: 0 39px;
		border-radius: 4px;
	}
	
	.detail-modal-balance-recharge-content .ant-input-number-handler-wrap {
		display: none;
	}
	
	
	.detail-modal-balance-recharge-content .ant-input-number-focused {
		box-shadow: none;
	}
	
	.detail-modal-balance-recharge-content .input-number-addon{
		height: 80px;
		padding-left: 0px;
		position: absolute;
		top: 0px;
		left: 0;
		text-align: right;
		line-height: 80px;
		
		font-size: 40px;
		font-weight: bold;
		color: #232529;
	}
	
	.detail-modal-balance-recharge-footer{
		height: 63px;
		border-top: 1px solid #DCDFE6;
	}
	
	.detail-modal-balance-recharge-footer .ant-input-number {
		font-size: 20px;
		line-height: 20px;
		border: none;
		font-weight: bold;
		color: #A6AEC2;
		height: 20px;
		width: 100px;
	}
	
	.detail-modal-balance-recharge-footer .ant-input-number-input {
		width: 100px;
		height: 20px;
		border-radius: 4px;
	}
	
	.detail-modal-balance-recharge-footer .ant-input-number-focused {
		box-shadow: none;
	}
	
	.detail-modal-balance-recharge-footer .ant-input-number-handler-wrap {
		display: none;
	}
</style>
