<template>
	<div>
		<div class="pd30">
			<div class="wxb-table-gray">
				<a-table rowKey="member_balance_logs_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					<div class="text-center" slot="original_deduction" slot-scope="original_deduction,record">
						¥{{original_deduction}}
					</div>
				 
					<div class="text-center flex alcenter" slot="money" slot-scope="record">
						<div>{{record.type==1 ? '+' : '-'}} ¥{{record.balance}}</div> 
						<div class="ft12 ftw400 cl-notice ml8" style="background: #F0F2FA;border-radius: 4px;padding: 4px 8px;" v-if="record.give_balance!=0">
							赠¥{{record.give_balance}}
						</div>
					</div>
					
					<div class="text-center" slot="give_balance" slot-scope="give_balance,record">
						+¥{{give_balance}}
					</div>
					
					<div class="text-center" slot="remarks" slot-scope="remarks,record">
						{{remarks ? remarks : '-'}}
					</div>
					
					<div class="text-center" slot="operator" slot-scope="record">
						<div v-if="record.operate_name && record.operate_mobile">
							<div class="ft14 cl-info">{{record.operate_name}}</div>
							<div class="ft12 cl-notice">({{record.operate_mobile}})</div>
						</div>
						<div v-else>-</div>
					</div>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '类型',dataIndex: 'info',align: 'center',ellipsis: true},
					// {title: '原本要扣除的金额',dataIndex: 'original_deduction',align: 'center',scopedSlots: {customRender: 'original_deduction'}},
					{title: '金额',key: 'money',align: 'center',scopedSlots: {customRender: 'money'}},
					// {title: '赠送的金额',dataIndex: 'give_balance',align: 'center',scopedSlots: {customRender: 'give_balance'}},
					{title: '时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '备注信息',dataIndex: 'remarks',align: 'center',scopedSlots: {customRender: 'remarks'}},
					{title: '操作人',key: 'operator',align: 'center',scopedSlots: {customRender: 'operator'}},
				],
				datas:[]
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getMemberBalanceLogsList',{
					member_id:this.member_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
</style>
