<template>
	<div>
		<a-modal title="办理次卡" :width="480" :visible="visible"  @cancel="handleCancel" :footer="null">
			<a-spin :spinning="loading">
				<div>
					<div class="flex center">
						<a-input-search placeholder="搜索次卡" v-model="search.keyword" style="width: 420px" @search="onSearch" />
					</div>
					<div v-if="datas.length>0" class="flex wrap center mt20">
						<div class="apply-card-item" v-for="(item,index) in datas">
							<div class="flex alcenter space">
								<div class="flex alcenter">
									<img :src="item.cover_img" class="apply-card-item-cover-img"/>
									<div class="ml15">
										<div class="ft14 ftw600 cl-black text-over">{{item.name}}</div>
										<div class="flex alcenter mt15">
											<div class="ft12 ftw400 cl-info">有效期：</div>
											<div class="ft12 ftw400 cl-balck">{{item.valid_time_format}}</div>
										</div>
										
										<div class="flex alcenter mt20" v-if="item.is_flash_sale==1 && item.is_flash_sale_expire==0">
											<div class="ft12 ftw400 cl-price ">秒杀价：¥</div>
											<div class="ft16 ftwblod cl-price">{{item.flash_price}}</div>
										</div>
										<div class="flex alcenter mt20" v-else>
											<div class="ft12 ftw400 cl-price ">¥</div>
											<div class="ft16 ftwblod cl-price">{{item.price}}</div>
										</div>
										
									</div>
								</div>
								
								<div>
									<a-button type="primary" @click="chooseIt(index)">办理</a-button>
								</div>
							</div>
							
							<img v-if="item.is_flash_sale==1 && item.is_flash_sale_expire==0" class="apply-card-item-flash-sale-tag" src="../../../../../assets/image/label_miaosha@3x.png"/>
							<div class="apply-card-item-flash-sale-djs" v-if="item.is_flash_sale==1 && item.is_flash_sale_expire==0">
								<div class="flex alcenter">
									<div class="ft10 ftw400 cl-w">距结束：</div>
									<time-countdown :t="item.flash_sale_djs" type="text" size="ft12 ftwbold cl-w"></time-countdown>
								</div>
							</div>
						</div>
					</div>
				
					<div v-else class="flex center mt20">
						<a-empty/>
					</div>
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
					</div>
				</div>
			</a-spin>
		</a-modal>
		
		<div v-if="setPriceLoading">
			<set-price  :member="member" :once_card="once_card" :visible="setPriceLoading" @cancel="cancelSetPrice" @ok="okSetPrice"></set-price>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	import setPrice from './applyCard/setPrice.vue';
	import timeCountdown from '../../../../../components/time/countdown.vue';
	export default {
		mixins:[listMixin],
		components:{
			setPrice,
			timeCountdown,
		},
		props: {
			member_id: {
				type: Number,
				default: 0
			},
			visible:{
				type:Boolean,
				default:true,
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				setPriceLoading:false,
				once_card:{},
				search:{
					keyword:'',
				},
				pagination:{
					total:0,
					pageSize:4,
					current:1,
				},
				member:{
					member_id:0,
					level_id:0,
				},
				datas:[],
				select_id:1,
			}
		},
		methods: {
			
			getLists(){
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/chooseOnceCard', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					member_id:this.member_id,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					if(res.member){
						this.member=res.member;
					}
					this.loading = false;
				}).catch(res => {
					this.loading = false;
				})
			},
			
			onSearch(){
				this.pagination.current=1;
				this.getLists();
			},
	
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			chooseIt(index) {
				this.once_card=this.datas[index];
				this.setPriceLoading=true;
				
			},
			cancelSetPrice(){
				this.setPriceLoading=false;
			},
			okSetPrice(){
				this.setPriceLoading=false;
				 this.$emit("ok");
			},
			
		}
	}
</script>

<style>
	.apply-card-item{
		width: 420px;
		height: 122px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #EBEDF5;
		margin-bottom: 10px;
		padding: 15px;
		position: relative;
		line-height: 1;
	}
	
	.apply-card-item-cover-img{
		width: 120px;
		height: 90px;
		border-radius: 4px;
	}
	
	.apply-card-item-flash-sale-tag{
		width: 48px;
		height: 48px;
		position: absolute;
		top:0;
		left:0;
	}
	
	.apply-card-item-flash-sale-djs{
		
		background: linear-gradient(270deg, #FF6600 0%, #FFAA00 100%);
		border-radius: 2px;
		opacity: 0.93;
		line-height: 1;
		padding: 1px 4px;
		
		position: absolute;
		bottom: 20px;
		left: 15px;
	}
</style>
